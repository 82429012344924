import styled from "@emotion/styled"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { breakpointDesktopWide, media } from "../common/mediaQueries"
import {
  Form,
  Formfield,
  Heading,
  Padding,
  PinkDelimiter,
  StrongText,
  styles,
  SubHeading,
  Textarea,
} from "../common/styles"
import { LinklessButton } from "../components/button"
import Layout from "../components/layout"
import { Link } from "../components/link"
import SEO from "../components/seo"
import SocialMediaMap from "../components/socialmedia"

export const anfragetypen = [
  "ICE SHOW",
  "Choreographie",
  "Eislaufkurse & Personal Training",
  "Mobile Eisbahn",
]

const Kontakt = () => {
  useEffect(() => {
    if (form.type === undefined) {
      const defaultForm = _.get(window, "location.search[1]")
        ? anfragetypen[_.get(window, "location.search[1]")]
        : anfragetypen[0]
      setForm({ type: defaultForm })
    }
  })

  const [form, setForm] = useState({ type: undefined })

  const handleChange = (value, target) => {
    setForm({ ...form, [target]: value })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...form }),
    })
      .then(() => alert("Ihre Nachricht wurde erfolgreich versandt!"))
      .catch(error =>
        alert(
          `Ihre Nachricht konnte nicht versandt werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich über ${
            _.keyBy(SocialMediaMap, "key").mail.identifier
          } an mich.`
        )
      )

    e.preventDefault()
  }
  return (
    <Layout>
      <SEO
        title="Kontakt"
        description="Sie sind interessiert an ICE SHOW, Choreographien oder Personal Training für Ihr Event oder im privaten Umfeld? Dann kontaktieren Sie mich gerne!"
      />
      <Wrapper>
        <Padding value="2vw" axis="y" />
        <Heading color={styles.colors.blue.dark}>Kontakt</Heading>
        <Padding value="1vw" axis="y" />
        <PinkDelimiter />
        <Padding value="2vw" axis="y" />
        <GreyBox>
          <SubHeading color={styles.colors.blue.dark}>
            Anfrageformular
          </SubHeading>
          <Padding value="2vw" axis="y" />
          <Form onSubmit={handleSubmit} data-netlify="true" name="contact">
            <FlexWrapper>
              {anfragetypen.map(item => (
                <Selectable
                  key={item}
                  active={item === form.type}
                  onClick={() => setForm({ ...form, type: item })}
                >
                  <StrongText>{item}</StrongText>
                </Selectable>
              ))}
            </FlexWrapper>
            <input type="hidden" name="type" value={form.type} />
            <Padding value="1vw" axis="y" />
            <FlexWrapper>
              <Formfield
                placeholder="Vorname"
                name="vorname"
                aria-label="Vorname"
                type="text"
                value={form.vorname}
                onChange={e => handleChange(e.target.value, "vorname")}
              />
              <Formfield
                placeholder="E-Mail*"
                name="mailadress"
                aria-label="Mailadresse"
                type="text"
                value={form.mailadress}
                onChange={e => handleChange(e.target.value, "mailadress")}
                required
              />
            </FlexWrapper>
            <Padding value="2vw" axis="x" />
            <FlexWrapper>
              <Formfield
                placeholder="Nachname"
                name="nachname"
                aria-label="Nachname"
                type="text"
                value={form.nachname}
                onChange={e => handleChange(e.target.value, "nachname")}
              />
              <Formfield
                placeholder="Rückrufnummer"
                name="telefonnummer"
                aria-label="Telefonnummer"
                type="text"
                value={form.telefonnummer}
                onChange={e => handleChange(e.target.value, "telefonnummer")}
              />
            </FlexWrapper>
            <Textarea
              placeholder="Nachricht"
              aria-label="Nachricht"
              name="message"
              type="text"
              value={form.message}
              onChange={e => handleChange(e.target.value, "message")}
            />
            <Padding value="2vw" axis="y" />
            <PrivacyText>
              Wir möchten Sie gerne auf unsere{" "}
              <Link
                to="/datenschutz"
                type="secondary"
                style={{
                  color: "inherit",
                  textDecoration: "underline",
                  textTransform: "none",
                  fontSize: "1em",
                }}
              >
                Datenschutzbestimmungen
              </Link>{" "}
              hinweisen.
            </PrivacyText>
            <Padding value="1vw" axis="y" />
            <div style={{ textAlign: "center" }}>
              <StyledLinklessButton
                content="Anfrage senden"
                look="primary"
                type="submit"
              />
            </div>
          </Form>
        </GreyBox>
        <Padding value="4vw" axis="y" />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`

const GreyBox = styled.div`
  width: 70vw;
  background-color: ${styles.colors.lightGrey};
  padding: 2vw;
  ${media.mobile} {
    width: 100vw;
    padding: 5vw 10vw 10vw 10vw;
    background-color: ${styles.colors.white};
  }
  ${media.wideDesktop} {
    max-width: ${breakpointDesktopWide}px;
  }
`

const PrivacyText = styled.div`
  font-size: 1.5vw;
  text-align: center;
  ${media.mobile} {
    font-size: 4vw;
  }
  ${media.wideDesktop} {
    font-size: 0.8vw;
  }
`

const Selectable = styled.div`
  flex: 1 1 100%;
  background-color: ${props =>
    props.active ? styles.colors.magenta : styles.colors.blue.dark};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5vw;
  color: white;
  cursor: pointer;
  padding: 1vw;
  ${media.mobile} {
    padding: 2vw !important;
    margin: 1vw 0;
    flex: 0 0 auto;
  }
  ${media.wideDesktop} {
    padding: 0.5vw !important;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  ${media.mobile} {
    flex-direction: column;
  }
`

const StyledLinklessButton = styled(LinklessButton)`
  align-self: center;
`

export default Kontakt
